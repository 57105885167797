import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          maxHeight="677px"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
          justifyBetween
        >
          <Header />
          <CFView textCenter w="100%" mb="30px">

            <CFView pulsate mt="15px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero}) 20% 0 / cover no-repeat`}
          zIndex={90}
          column
          justifyStart
        >
          <Header />
          <CFView row justifyCenter alignStart h="100vh">
            <CFView
              bg="rgba(0, 0, 0, 0.7)"
              pv="8px"
              row
              alignCenter
              justifyCenter
              w="100%"
              mt="auto"
            >
              <CFImage
                h="32px"
                src={heroText}
                alt="Chibop hero text"
                zIndex={98}
              />
              <CFView ml="30px" pulsate>
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
