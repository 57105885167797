import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderPickupButton, mobileOrderPickupButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/VTZGNhiI9Iv5mp8qw6vD/locations/rt8GZmwXGp4pqCVkHyIw`}
            >
              <CFView hover>
                <CFImage
                  src={mobileOrderPickupButton}
                  w="90%"
                  maxWidth="350px"
                  alt="About"
                />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/VTZGNhiI9Iv5mp8qw6vD/locations/rt8GZmwXGp4pqCVkHyIw`}
            >
              <CFView w="310px" hover>
                <CFImage
                  absolute
                  bottom="-20px"
                  src={orderPickupButton}
                  maxWidth="300px"
                  alt="About"
                />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
